// extracted by mini-css-extract-plugin
export var avis = "details-module--avis--zEwPX";
export var banner = "details-module--banner--QStUZ";
export var button = "details-module--button--N3qCy";
export var checkBorder = "details-module--checkBorder--D2Gx-";
export var composition = "details-module--composition--gYYaF";
export var compositionButton = "details-module--compositionButton--2PiyH";
export var compositionContainer = "details-module--compositionContainer--houdQ";
export var compositionDescription = "details-module--compositionDescription--8-frh";
export var compositionDetails = "details-module--compositionDetails--1mbmA";
export var compositionTitle = "details-module--compositionTitle--qveb5";
export var container = "details-module--container--gDHVy";
export var description = "details-module--description--AVQOw";
export var descriptionContainer = "details-module--descriptionContainer--ubh4l";
export var dosageContainer = "details-module--dosageContainer--reWyc";
export var dosageTitle = "details-module--dosageTitle---tuqL";
export var explanationContainer = "details-module--explanationContainer--SqO9E";
export var include_items = "details-module--include_items--ngkvZ";
export var ingredients = "details-module--ingredients--GAMLF";
export var instructions = "details-module--instructions--7GlKW";
export var instructionsMore = "details-module--instructionsMore--8OG9q";
export var largeImage = "details-module--largeImage--CUT82";
export var month = "details-module--month--mMI5u";
export var priceContainer = "details-module--priceContainer--TEQ5y";
export var priceMonth = "details-module--priceMonth--UOuMC";
export var productContainer = "details-module--productContainer--f9B3o";
export var productImages = "details-module--productImages--L0DHh";
export var programTitle = "details-module--programTitle--x7KpC";
export var quantityButton = "details-module--quantityButton--b3Pg1";
export var quantityControl = "details-module--quantityControl--aju5L";
export var quantityInput = "details-module--quantityInput--iWEiY";
export var regularization = "details-module--regularization--rkxyL";
export var seeMore = "details-module--seeMore--Be53r";
export var smallImage = "details-module--smallImage--unIhN";
export var starElement = "details-module--starElement--X8ANh";
export var stars = "details-module--stars--sIME9";
export var start_price = "details-module--start_price--RPjna";
export var supplementsTag = "details-module--supplementsTag--uXweC";
export var underline = "details-module--underline--aEw0-";