import React, { use, useEffect, useState, useContext } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import * as styles from '../modules/details.module.scss';
import { Star, Check, Plus, Minus } from 'lucide-react';
import { GatsbyImage } from 'gatsby-plugin-image';
import CartContext from '../context/CartContext';
import { navigate } from 'gatsby';

const StarRating = ({ rating, star, starEmpty }) => {
  return (
    <>
      {[...Array(5)].map((_, index) => {
        const isFull = index + 1 <= rating;
        // const isHalf = index + 0.5 === rating;

        return <img key={index} src={isFull ? star : starEmpty} alt="star" className={styles.containerStar} />;
      })}
    </>
  );
};

const Details = ({ pageContext }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const { cart, setCart } = useContext(CartContext);
  const [quantity, setQuantity] = useState(
    cart?.contents.items.find((elem) => elem.id === pageContext.productId)?.quantity || 0
  );

  const quantityChange = (change) => {
    setQuantity((prevQuantity) => {
      if (prevQuantity + change < 0) return prevQuantity;
      return prevQuantity + change;
    });
  };

  const addProgamToCart = (period) => {
    setCart('add', 'program', pageContext.slug);
    setCart('add', 'membershipPeriod', period);
    navigate(`/panier`);
  };

  const addToCart = () => {
    if (cart?.contents.program && cart?.contents.program === 'shop') {
    } else {
      setCart('add', 'membershipPeriod', 'monthly');
      setCart('add', 'program', 'shop');
    }
    if (quantity > 0) {
      setCart('add', 'product', pageContext.productId, quantity);
      navigate(`/panier`);
    }
  };

  const toggleDescription = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <Layout>
      <SEO
        title={`Programme ${pageContext.slug} – Boostez votre fertilité naturellement avec BeMum`}
        description={`Un programme pour optimiser votre fertilité avec conseils sur-mesure, suivi expert et méthodes naturelles pour concevoir sereinement.`}
        canonical={`https://www.bemum.co/product/${pageContext.slug}/`}
      />
      <div className={styles.banner}></div>

      {pageContext.program_tag && <h3 className={styles.supplementsTag}>{pageContext.program_tag}</h3>}

      <section className={styles.productContainer}>
        <div className={styles.productImages}>
          {pageContext.program_images.map((item, index) => (
            <div key={index} className={index === 2 ? styles.largeImage : styles.smallImage}>
              <GatsbyImage image={item.img.childImageSharp.gatsbyImageData} alt={item.alt} />
            </div>
          ))}
        </div>

        <div className={styles.descriptionContainer}>
          <h2 className={styles.programTitle}>{pageContext.program}</h2>
          <p className={styles.stars}>
            <StarRating rating={4} star={pageContext.star.publicURL} starEmpty={pageContext.star_empty.publicURL} />
            <span className={styles.avis}>88 avis</span>
          </p>
          <p className={styles.start_price}>{pageContext.start_price}</p>
          <p dangerouslySetInnerHTML={{ __html: pageContext.description }} className={styles.description} />
          <ul>
            {pageContext.include_items &&
              pageContext.include_items.map((item, index) => (
                <li key={index} className={styles.include_items}>
                  <span className={styles.checkBorder}>
                    <Check stroke={'#F19EDC'} />
                  </span>
                  {item.text}
                </li>
              ))}
          </ul>

          {pageContext.page === 'boutique' ? (
            <div>
              <div className={styles.quantityControl}>
                <a
                  className={styles.quantityButton}
                  onClick={() => quantity > 0 && quantityChange(-1)}
                  style={{
                    cursor: quantity <= 0 ? 'not-allowed' : 'pointer',
                    opacity: quantity <= 0 ? 0.5 : 1,
                  }}
                >
                  <Minus />
                </a>

                <input type="number" value={quantity || 0} readOnly className={styles.quantityInput} />

                <a className={styles.quantityButton} onClick={() => quantityChange(1)}>
                  <Plus />
                </a>
              </div>
              <button className={styles.button} onClick={() => addToCart()}>
                Ajouter au panier
              </button>
            </div>
          ) : (
            <ul className={styles.priceContainer}>
              {pageContext.price.map((price, index) => (
                <li key={index} className={styles.priceMonth} onClick={() => addProgamToCart(price.period)}>
                  <p className={styles.month}>{price.month}</p>
                  <p>{price.text}</p>
                </li>
              ))}
            </ul>
          )}
        </div>
      </section>

      {pageContext.composition && (
        <section className={styles.compositionContainer}>
          <div className={styles.composition}>
            <h2 className={styles.compositionTitle}>{pageContext.composition.title}</h2>
            <div className={styles.compositionDetails}>
              {pageContext.composition.items.map((item, index) => (
                <div composition={styles.seeMore}>
                  <p dangerouslySetInnerHTML={{ __html: item.description }} className={styles.compositionDescription} />
                  <button className={styles.compositionButton} onClick={() => toggleDescription(index)}>
                    {openIndex === index ? 'Masquer' : 'Voir la composition détaillée'}
                  </button>
                  {openIndex === index && (
                    <p dangerouslySetInnerHTML={{ __html: item.ingredients }} className={styles.ingredients} />
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.explanationContainer}>
            <ul>
              {pageContext.regularization.map((item, index) => (
                <li className={styles.regularization}>
                  <span className={styles.checkBorder}>
                    <Check stroke={'#F19EDC'} />
                  </span>
                  {item.text}
                </li>
              ))}
            </ul>
            <div className={styles.container}>
              <div className={styles.dosageContainer}>
                <h2 className={styles.dosageTitle}>{pageContext.dosage.title}</h2>
                <div className={styles.underline}></div>
                <ul>
                  {pageContext.dosage.items.map((instructions, index) => (
                    <li dangerouslySetInnerHTML={{ __html: instructions.text }} className={styles.instructions} />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      )}
    </Layout>
  );
};

export default Details;
